import AnalyticsIcon from "./icons/ChartPie";
import DoctorIcon from "@material-ui/icons/MedicalServices";
import AgendaIcon from "@mui/icons-material/DateRange";
import UserIcon from "./icons/Users";
import CampaignIcon from "@material-ui/icons/Campaign";
import ETLRunIcon from "@material-ui/icons/DataUsage";
import ETLTaskIcon from "@material-ui/icons/Engineering";
import BrandIcon from "@material-ui/icons/LocalMall";
import AdvertiserIcon from "./icons/Briefcase";
import AccountIcon from "@material-ui/icons/Tag";
import PlatformIcon from "@material-ui/icons/Apps";
import PostIcon from "@material-ui/icons/Image";
import PostBoostCampaignIcon from "@material-ui/icons/Construction";
import ProblemTypeIcon from "@material-ui/icons/Warning";
import DataOriginIcon from "@material-ui/icons/FolderOpen";
import DataImportIcon from "@material-ui/icons/Upload";
import DataProcedureIcon from '@mui/icons-material/Settings';
import AdAccountIcon from "@material-ui/icons/Home";
import CountryIcon from "@material-ui/icons/Language";
import MetricIcon from "@material-ui/icons/Equalizer";
import OfferIcon from "@material-ui/icons/LocalOffer";
import AdOpsCampaignIcon from "@material-ui/icons/Construction";
import LineItemIcon from "@mui/icons-material/Toc";
import AdPlatformIcon from "@material-ui/icons/Apps";
import AdFormatIcon from "@mui/icons-material/FitScreen";
import AdOpsObjectiveIcon from "@mui/icons-material/SportsScore";
import SpecTemplateIcon from "@mui/icons-material/ImageAspectRatio";
import LanguageIcon from "@mui/icons-material/Translate"

export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const REPORTING_TEMPLATES = [
  "Video",
  "Image 3 phases - Competition",
  "Image 3 phases - Engagement",
  "Image 3 phases - Reach",
  "Carousel Reach",
  "Not supported",
];

export const ASSET_TYPES = ["Upload", "Post", "Dark post", "Post creator"];

export const FORMAT_POSTS = ["Image",  "Carousel", "Video", "Article"]

export const ENTITY = {
  ADVERTISER: {
    type: {
      singular: "advertiser",
      plural: "advertisers",
    },
    label: {
      // First letter in lower case
      singular: "advertiser",
      plural: "advertisers",
      // First letter in upper case
      upFirstSingular: "Advertiser",
      upFirstPlural: "Advertisers",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  BRAND: {
    type: {
      singular: "brand",
      plural: "brands",
    },
    label: {
      // First letter in lower case
      singular: "brand",
      plural: "brands",
      // First letter in upper case
      upFirstSingular: "Brand",
      upFirstPlural: "Brands",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  CAMPAIGN: {
    type: {
      singular: "campaign",
      plural: "campaigns",
    },
    label: {
      // First letter in lower case
      singular: "campaign",
      plural: "campaigns",
      // First letter in upper case
      upFirstSingular: "Campaign",
      upFirstPlural: "Campaigns",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  LINEITEM: {
    type: {
      singular: "lineItem",
      plural: "lineItems",
    },
    label: {
      // First letter in lower case
      singular: "line item",
      plural: "line items",
      // First letter in upper case
      upFirstSingular: "Line item",
      upFirstPlural: "Line items",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  ADOPSCAMPAIGN: {
    type: {
      singular: "adOpsCampaign",
      plural: "adOpsCampaigns",
    },
    label: {
      // First letter in lower case
      singular: "ad ops campaign",
      plural: "ad ops campaigns",
      // First letter in upper case
      upFirstSingular: "Ad ops campaign",
      upFirstPlural: "Ad ops campaigns",
    },
    canCreate: false,
    canEdit: false,
    canDelete: false,
    nameField: "name"
  },
  ACCOUNT: {
    type: {
      singular: "account",
      plural: "accounts",
    },
    label: {
      // First letter in lower case
      singular: "account",
      plural: "accounts",
      // First letter in upper case
      upFirstSingular: "Account",
      upFirstPlural: "Accounts",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "url"
  },
  POST: {
    type: {
      singular: "post",
      plural: "posts",
    },
    label: {
      // First letter in lower case
      singular: "post",
      plural: "posts",
      // First letter in upper case
      upFirstSingular: "Post",
      upFirstPlural: "Posts",
    },
    canCreate: false,
    canEdit: true,
    canDelete: false,
    nameField: "caption"
  },
  USER: {
    type: {
      singular: "user",
      plural: "users",
    },
    label: {
      // First letter in lower case
      singular: "user",
      plural: "users",
      // First letter in upper case
      upFirstSingular: "User",
      upFirstPlural: "Users",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "fullName"
  },
  ETLRUN: {
    type: {
      singular: "ETLRun",
      plural: "ETLRuns",
    },
    label: {
      // First letter in lower case
      singular: "ETL run",
      plural: "ETL runs",
      // First letter in upper case
      upFirstSingular: "ETL run",
      upFirstPlural: "ETL runs",
    },
    canCreate: false,
    canEdit: false,
    canDelete: false,
    nameField: "name"
  },
  ETLTASK: {
    type: {
      singular: "ETLTask",
      plural: "ETLTasks",
    },
    label: {
      // First letter in lower case
      singular: "ETL task",
      plural: "ETL tasks",
      // First letter in upper case
      upFirstSingular: "ETL task",
      upFirstPlural: "ETL tasks",
    },
    canCreate: false,
    canEdit: false,
    canDelete: false,
    nameField: "type"
  },
  ADPLATFORM: {
    type: {
      singular: "adPlatform",
      plural: "adPlatforms",
    },
    label: {
      // First letter in lower case
      singular: "ad platform",
      plural: "ad platforms",
      // First letter in upper case
      upFirstSingular: "Ad platform",
      upFirstPlural: "Ad platforms",
    },
    canCreate: false,
    canEdit: false,
    canDelete: false,
    nameField: "name"
  },
  ADACCOUNT: {
    type: {
      singular: "adAccount",
      plural: "adAccounts",
    },
    label: {
      // First letter in lower case
      singular: "ad account",
      plural: "ad accounts",
      // First letter in upper case
      upFirstSingular: "Ad account",
      upFirstPlural: "Ad accounts",
    },
    canCreate: false,
    canEdit: true,
    canDelete: false,
    nameField: "name"
  },
  PLATFORM: {
    type: {
      singular: "platform",
      plural: "platforms",
    },
    label: {
      // First letter in lower case
      singular: "platform",
      plural: "platforms",
      // First letter in upper case
      upFirstSingular: "Platform",
      upFirstPlural: "Platforms",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  OFFER: {
    type: {
      singular: "offer",
      plural: "offers",
    },
    label: {
      // First letter in lower case
      singular: "offer",
      plural: "offers",
      // First letter in upper case
      upFirstSingular: "Offer",
      upFirstPlural: "Offers",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  METRIC: {
    type: {
      singular: "metric",
      plural: "metrics",
    },
    label: {
      // First letter in lower case
      singular: "metric",
      plural: "metrics",
      // First letter in upper case
      upFirstSingular: "Metric",
      upFirstPlural: "Metrics",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  PROBLEMTYPE: {
    type: {
      singular: "problemType",
      plural: "problemTypes",
    },
    label: {
      // First letter in lower case
      singular: "problem type",
      plural: "problem types",
      // First letter in upper case
      upFirstSingular: "Problem type",
      upFirstPlural: "Problem types",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  DATAORIGIN: {
    type: {
      singular: "dataOrigin",
      plural: "dataOrigins",
    },
    label: {
      // First letter in lower case
      singular: "data origin",
      plural: "data origins",
      // First letter in upper case
      upFirstSingular: "Data origin",
      upFirstPlural: "Data origins",
    },
    canCreate: false,
    canEdit: true,
    canDelete: false,
    nameField: "name"
  },
  ADFORMAT: {
    type: {
      singular: "adFormat",
      plural: "adFormats",
    },
    label: {
      // First letter in lower case
      singular: "ad format",
      plural: "ad formats",
      // First letter in upper case
      upFirstSingular: "Ad format",
      upFirstPlural: "Ad formats",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  SPECTEMPLATE: {
    type: {
      singular: "specTemplate",
      plural: "specTemplates",
    },
    label: {
      // First letter in lower case
      singular: "spec template",
      plural: "spec templates",
      // First letter in upper case
      upFirstSingular: "Spec template",
      upFirstPlural: "Spec templates",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  COUNTRY: {
    type: {
      singular: "country",
      plural: "countries",
    },
    label: {
      // First letter in lower case
      singular: "country",
      plural: "countries",
      // First letter in upper case
      upFirstSingular: "Country",
      upFirstPlural: "Countries",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  ADOPSOBJECTIVE: {
    type: {
      singular: "adOpsObjective",
      plural: "adOpsObjectives",
    },
    label: {
      // First letter in lower case
      singular: "ad ops objective",
      plural: "ad ops objectives",
      // First letter in upper case
      upFirstSingular: "Ad ops objective",
      upFirstPlural: "Ad ops objectives",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
  POSTBOOSTCAMPAIGN: {
    type: {
      singular: "postBoostCampaign",
      plural: "postBoostCampaigns",
    },
    label: {
      // First letter in lower case
      singular: "post boost campaign",
      plural: "post boost campaigns",
      // First letter in upper case
      upFirstSingular: "Post boost campaign",
      upFirstPlural: "Post boost campaigns",
    },
    canCreate: false,
    canEdit: false,
    canDelete: false,
    nameField: "name"
  },
  LANGUAGE: {
    type: {
      singular: "language",
      plural: "languages",
    },
    label: {
      // First letter in lower case
      singular: "language",
      plural: "languages",
      // First letter in upper case
      upFirstSingular: "Language",
      upFirstPlural: "Languages",
    },
    canCreate: true,
    canEdit: true,
    canDelete: true,
    nameField: "name"
  },
};

export const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

export const LIST_ICONS = {
  EDIT: "edit",
  EXTERNAL: "external",
  EYE: "eye",
  DOCUMENT: "document",
  GOAL: "goal",
  BAR: "bar",
  REPORTING: "reporting",
  CONTENT: "content",
  WORKFLOW: "workflow",
  IMAGE: "image",
  SETTINGS: "settings",
};

export const GENDERS = [
  {
    id: "ALL",
    name: "Mixte",
  },
  {
    id: "FEMALE",
    name: "Femmes",
  },
  {
    id: "MALE",
    name: "Hommes",
  },
];

export const AGES = Array(101)
  .fill(0)
  .map((n, i) => n + i);

export const CAMPAIGN_STATUS = [
  {
    name: "Brief",
    bgcolor: "#67b4e0",
    color: "black",
    description: "Please send a proposal to the client",
  },
  {
    name: "Proposal",
    bgcolor: "#0f3999",
    color: "white",
    description: "Please validate the proposal",
  },
  {
    name: "Assets",
    bgcolor: "#e0b641",
    color: "black",
    description: "Please enter and validate line items assets",
  },
  {
    name: "Setup",
    bgcolor: "#a86c05",
    color: "white",
    description: "Please do the setup",
  },
  {
    name: "Waiting start",
    bgcolor: "#67e098",
    color: "black",
    description: "Please wait for start date",
  },
  {
    name: "Waiting LIVE check",
    bgcolor: "orange",
    color: "black",
    description: "Please check if LIVE",
  },
  {
    name: "Live",
    bgcolor: "green",
    color: "white",
    description: "Please wait for end date",
  },
  {
    name: "Report",
    bgcolor: "#d96de3",
    color: "black",
    description: "Please finalize margin and send end report to the client",
  },
  {
    name: "Invoice",
    bgcolor: "#63046b",
    color: "white",
    description: "Please do the invoicing",
  },
  {
    name: "Finished",
    bgcolor: "black",
    color: "white",
    description: "Nothing to do",
  },
];

export const CAMPAIGN_DOCTOR_OFFSET_DAYS = {
  // relative to start date
  PROPOSAL: -5,
  PROPOSAL_VALIDATED: -4,
  ASSETS_VALID: -3,
  SETUP_DONE: -1,
  LIVE_CHECK: 0,
  EMAIL_START: 0,
  TARGETING_CHECK: 1,
  CONFIG_TPC_REPORTING: 2,
  GOAL_CHECK_START: 3,
  // relative to end date
  GOAL_CHECK_MID: -5,
  EMAIL_END: 1,
  MARGIN_FINALIZED: 3,
  REPORT: 14,
  INVOICE: 20,
};

export const MODULES = {
  SOCIAL: "social",
  CAMPAIGN: "campaigns",
  CLIENT: "clients",
  OFFER: "offers",
  ADMIN: "admin",
  ETL: "ETL",
};

export const MODULES_LIST = [
  {
    id: "social",
    name: "Social",
    shortName: "Soc",
  },
  {
    id: "campaigns",
    name: "Campaigns",
    shortName: "Cam",
  },
  {
    id: "clients",
    name: "Clients",
    shortName: "Cli",
  },
  {
    id: "offers",
    name: "Offers",
    shortName: "Off",
  },
  {
    id: "admin",
    name: "Admin",
    shortName: "Adm",
  },
  {
    id: "ETL",
    name: "ETL",
    shortName: "ETL",
  },
];

export const FEATURES = [
  // SOCIAL MODULE
  {
    id: "account",
    name: "Accounts",
    module: "social",
    options: {
      canCreate: ENTITY.ACCOUNT.canCreate,
      canUpdate: ENTITY.ACCOUNT.canEdit,
      canDelete: ENTITY.ACCOUNT.canDelete,
    },
    path: "/app/accounts",
    icon: <AccountIcon fontSize="small" />,
  },
  {
    id: "post",
    name: "Posts",
    module: "social",
    options: {
      canCreate: ENTITY.POST.canCreate,
      canUpdate: ENTITY.POST.canEdit,
      canDelete: ENTITY.POST.canDelete,
    },
    path: "/app/posts",
    icon: <PostIcon fontSize="small" />,
  },
  {
    id: "postBoostCampaign",
    name: "Post boost campaigns",
    module: "social",
    options: {
      canCreate: ENTITY.POSTBOOSTCAMPAIGN.canCreate,
      // the code is different here
      // thats because POSTBOOSTCAMPAIGN is not editable (=> ENTITY.POSTBOOSTCAMPAIGN.canEdit is FALSE)
      // unless in the Doctor => so we need canUpdate to be true so that we can handle update permission in the Doctor
      canUpdate: true,
      canDelete: ENTITY.POSTBOOSTCAMPAIGN.canDelete,
    },
    path: "/app/postBoostCampaigns",
    icon: <PostBoostCampaignIcon fontSize="small" />,
  },
  {
    id: "postBoostDoctor",
    name: "Doctor",
    module: "social",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/post-boost-doctor/",
    icon: <DoctorIcon fontSize="small" />,
  },
  // CAMPAIGNS MODULE
  {
    id: "campaign",
    name: "Campaigns",
    module: "campaigns",
    options: {
      canCreate: ENTITY.CAMPAIGN.canCreate,
      canUpdate: ENTITY.CAMPAIGN.canEdit,
      canDelete: ENTITY.CAMPAIGN.canDelete,
    },
    path: "/app/campaigns",
    icon: <CampaignIcon fontSize="small" />,
  },
  {
    id: "lineItem",
    name: "Line items",
    module: "campaigns",
    options: {
      canCreate: ENTITY.LINEITEM.canCreate,
      canUpdate: ENTITY.LINEITEM.canEdit,
      canDelete: ENTITY.LINEITEM.canDelete,
    },
    path: "/app/lineItems",
    icon: <LineItemIcon fontSize="small" />,
  },
  {
    id: "adOpsCampaign",
    name: "Ad Ops Campaigns",
    module: "campaigns",
    options: {
      canCreate: ENTITY.ADOPSCAMPAIGN.canCreate,
      canUpdate: ENTITY.ADOPSCAMPAIGN.canEdit,
      canDelete: ENTITY.ADOPSCAMPAIGN.canDelete,
    },
    path: "/app/adOpsCampaigns",
    icon: <AdOpsCampaignIcon fontSize="small" />,
  },
  {
    id: "campaignAnalytics",
    name: "Analytics",
    module: "campaigns",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/campaign-analytics",
    icon: <AnalyticsIcon fontSize="small" />,
  },
  {
    id: "campaignDoctor",
    name: "Doctor",
    module: "campaigns",
    options: {
      canCreate: false,
      canUpdate: true,
      canDelete: false,
    },
    path: "/app/campaign-doctor/",
    icon: <DoctorIcon fontSize="small" />,
  },
  {
    id: "campaignAgenda",
    name: "Agenda",
    module: "campaigns",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/campaign-agenda/",
    icon: <AgendaIcon fontSize="small" />,
  },
  // CLIENT MODULE
  {
    id: "advertiser",
    name: "Advertisers",
    module: "clients",
    options: {
      canCreate: ENTITY.ADVERTISER.canCreate,
      canUpdate: ENTITY.ADVERTISER.canEdit,
      canDelete: ENTITY.ADVERTISER.canDelete,
    },
    path: "/app/advertisers",
    icon: <AdvertiserIcon fontSize="small" />,
  },
  {
    id: "brand",
    name: "Brands",
    module: "clients",
    options: {
      canCreate: ENTITY.BRAND.canCreate,
      canUpdate: ENTITY.BRAND.canEdit,
      canDelete: ENTITY.BRAND.canDelete,
    },
    path: "/app/brands",
    icon: <BrandIcon fontSize="small" />,
  },
  // OFFER MODULE
  {
    id: "platform",
    name: "Platforms",
    module: "offers",
    options: {
      canCreate: ENTITY.PLATFORM.canCreate,
      canUpdate: ENTITY.PLATFORM.canEdit,
      canDelete: ENTITY.PLATFORM.canDelete,
    },
    path: "/app/platforms",
    icon: <PlatformIcon fontSize="small" />,
  },
  {
    id: "adFormat",
    name: "Ad formats",
    module: "offers",
    options: {
      canCreate: ENTITY.ADFORMAT.canCreate,
      canUpdate: ENTITY.ADFORMAT.canEdit,
      canDelete: ENTITY.ADFORMAT.canDelete,
    },
    path: "/app/adFormats",
    icon: <AdFormatIcon fontSize="small" />,
  },
  {
    id: "specTemplate",
    name: "Spec templates",
    module: "offers",
    options: {
      canCreate: ENTITY.SPECTEMPLATE.canCreate,
      canUpdate: ENTITY.SPECTEMPLATE.canEdit,
      canDelete: ENTITY.SPECTEMPLATE.canDelete,
    },
    path: "/app/specTemplates",
    icon: <SpecTemplateIcon fontSize="small" />,
  },
  {
    id: "metric",
    name: "Metrics",
    module: "offers",
    options: {
      canCreate: ENTITY.METRIC.canCreate,
      canUpdate: ENTITY.METRIC.canEdit,
      canDelete: ENTITY.METRIC.canDelete,
    },
    path: "/app/metrics",
    icon: <MetricIcon fontSize="small" />,
  },
  {
    id: "offer",
    name: "Offers",
    module: "offers",
    options: {
      canCreate: ENTITY.OFFER.canCreate,
      canUpdate: ENTITY.OFFER.canEdit,
      canDelete: ENTITY.OFFER.canDelete,
    },
    path: "/app/offers",
    icon: <OfferIcon fontSize="small" />,
  },
  // ADMIN MODULE
  {
    id: "user",
    name: "Users",
    module: "admin",
    options: {
      canCreate: ENTITY.USER.canCreate,
      canUpdate: ENTITY.USER.canEdit,
      canDelete: ENTITY.USER.canDelete,
    },
    path: "/app/users",
    icon: <UserIcon fontSize="small" />,
  },
  {
    id: "dataOrigin",
    name: "Data origins",
    module: "admin",
    options: {
      canCreate: ENTITY.DATAORIGIN.canCreate,
      canUpdate: ENTITY.DATAORIGIN.canEdit,
      canDelete: ENTITY.DATAORIGIN.canDelete,
    },
    path: "/app/dataOrigins",
    icon: <DataOriginIcon fontSize="small" />,
  },
  {
    id: "adPlatform",
    name: "Ad platforms",
    module: "admin",
    options: {
      canCreate: ENTITY.ADPLATFORM.canCreate,
      // the code is different here
      // thats because ADPLATFORM is not editable (=> ENTITY.ADPLATFORM.canEdit is FALSE)
      // except the logo => so we need canUpdate to be true so that we can handle update permission in the UI
      canUpdate: true,
      canDelete: ENTITY.ADPLATFORM.canDelete,
    },
    path: "/app/adPlatforms",
    icon: <AdPlatformIcon fontSize="small" />,
  },
  {
    id: "adAccount",
    name: "Ad accounts",
    module: "admin",
    options: {
      canCreate: ENTITY.ADACCOUNT.canCreate,
      canUpdate: ENTITY.ADACCOUNT.canEdit,
      canDelete: ENTITY.ADACCOUNT.canDelete,
    },
    path: "/app/adAccounts",
    icon: <AdAccountIcon fontSize="small" />,
  },
  {
    id: "problemType",
    name: "Problem types",
    module: "admin",
    options: {
      canCreate: ENTITY.PROBLEMTYPE.canCreate,
      canUpdate: ENTITY.PROBLEMTYPE.canEdit,
      canDelete: ENTITY.PROBLEMTYPE.canDelete,
    },
    path: "/app/problemTypes",
    icon: <ProblemTypeIcon fontSize="small" />,
  },
  {
    id: "country",
    name: "Countries",
    module: "admin",
    options: {
      canCreate: ENTITY.COUNTRY.canCreate,
      canUpdate: ENTITY.COUNTRY.canEdit,
      canDelete: ENTITY.COUNTRY.canDelete,
    },
    path: "/app/countries",
    icon: <CountryIcon fontSize="small" />,
  },
  {
    id: "language",
    name: "Languages",
    module: "admin",
    options: {
      canCreate: ENTITY.LANGUAGE.canCreate,
      canUpdate: ENTITY.LANGUAGE.canEdit,
      canDelete: ENTITY.LANGUAGE.canDelete,
    },
    path: "/app/languages",
    icon: <LanguageIcon fontSize="small" />,
  },
  {
    id: "adOpsObjective",
    name: "Ad ops objectives",
    module: "admin",
    options: {
      canCreate: ENTITY.ADOPSOBJECTIVE.canCreate,
      canUpdate: ENTITY.ADOPSOBJECTIVE.canEdit,
      canDelete: ENTITY.ADOPSOBJECTIVE.canDelete,
    },
    path: "/app/adOpsObjectives",
    icon: <AdOpsObjectiveIcon fontSize="small" />,
  },
  {
    id: "dataImports",
    name: "Data imports",
    module: "admin",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/data-imports",
    icon: <DataImportIcon fontSize="small" />,
  },
  {
    id: "dataProcedures",
    name: "Data procedures",
    module: "admin",
    options: {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    },
    path: "/app/data-procedures",
    icon: <DataProcedureIcon fontSize="small" />,
  },
  // ETL MODULE
  {
    id: "ETLRun",
    name: "ETL Runs",
    module: "ETL",
    options: {
      canCreate: ENTITY.ETLRUN.canCreate,
      canUpdate: ENTITY.ETLRUN.canEdit,
      canDelete: ENTITY.ETLRUN.canDelete,
    },
    path: "/app/ETLRuns",
    icon: <ETLRunIcon fontSize="small" />,
  },
  {
    id: "ETLTask",
    name: "ETL Tasks",
    module: "ETL",
    options: {
      canCreate: ENTITY.ETLTASK.canCreate,
      canUpdate: ENTITY.ETLTASK.canEdit,
      canDelete: ENTITY.ETLTASK.canDelete,
    },
    path: "/app/ETLTasks",
    icon: <ETLTaskIcon fontSize="small" />,
  },
];

export const STALE_TIME = 2*60 * 1000; // 5 minutes

export const DEFAULT_ROWS_PER_PAGE = 25;
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50, 100];
