import * as api from "../apis/adOpsCampaignsApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getPaginatedAdOpsCampaignsQueryOptions = ({
  filterText,
  filterHasNoLineItem,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "adOpsCampaigns",
      filterText,
      filterHasNoLineItem,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedAdOpsCampaigns({
        filterText,
        filterHasNoLineItem,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPaginatedAdOpsCampaignsByAdAccountQueryOptions = ({
  adAccountId,
  filterText,
  filterHasNoLineItem,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "adAccount.adOpsCampaigns",
      adAccountId,
      filterText,
      filterHasNoLineItem,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedAdOpsCampaignsByAdAccount({
        adAccountId,
        filterText,
        filterHasNoLineItem,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdOpsCampaignsByLineItemQueryOptions = (lineItemId) =>
  queryOptions({
    queryKey: ["lineItem.adOpsCampaigns", lineItemId],
    queryFn: () => api.getAdOpsCampaignsByLineItem(lineItemId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdOpsCampaignsByCampaignQueryOptions = (campaignId) =>
  queryOptions({
    queryKey: ["campaign.adOpsCampaigns", campaignId],
    queryFn: () => api.getAdOpsCampaignsByCampaign(campaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdOpsCampaignQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["adOpsCampaign", id],
    queryFn: () => api.getAdOpsCampaign(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // we try to find the adOpsCampaign in one of the "adOpsCampaigns" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["adOpsCampaigns"] })
        .forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((adOpsCampaign) => {
              if (adOpsCampaign.id === id) {
                found = adOpsCampaign;
                queryKey = query[0];
              }
            });
          }
        });

      // otherwise, we try to find the adOpsCampaign in one of the "lineItem.adOpsCampaigns" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["lineItem.adOpsCampaigns"] })
          .forEach((query) => {
            const data = query[1];
            if (data) {
              data.forEach((adOpsCampaign) => {
                if (adOpsCampaign.id === id) {
                  found = adOpsCampaign;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      // otherwise, we try to find the adOpsCampaign in one of the "campaign.adOpsCampaigns" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["campaign.adOpsCampaigns"] })
          .forEach((query) => {
            const data = query[1];
            if (data) {
              data.forEach((adOpsCampaign) => {
                if (adOpsCampaign.id === id) {
                  found = adOpsCampaign;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      // otherwise, we try to find the adOpsCampaign in one of the "adAccount.adOpsCampaigns" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["adAccount.adOpsCampaigns"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((adOpsCampaign) => {
                if (adOpsCampaign.id === id) {
                  found = adOpsCampaign;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

const getAdOpsCampaignStatsByGenderQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByGender", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByGender(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAdOpsCampaignStatsByAgeQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByAge", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByAge(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAdOpsCampaignStatsByPlacementQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByPlacement", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByPlacement(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAdOpsCampaignStatsByPlatformQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByPlatform", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByPlatform(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAdOpsCampaignStatsByDevicePlatformQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByDevicePlatform", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByDevicePlatform(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAdOpsCampaignStatsByDeviceQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByDevice", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByDevice(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAdOpsCampaignStatsByAdQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByAd", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByAd(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAdOpsCampaignStatsByAdSetQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByAdSet", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByAdSet(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

const getAdOpsCampaignStatsByDayQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.statsByDay", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignStatsByDay(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getAdOpsCampaignDataCheckQueryOptions = (adOpsCampaignId) =>
  queryOptions({
    queryKey: ["adOpsCampaign.dataCheck", adOpsCampaignId],
    queryFn: () => api.getAdOpsCampaignDataCheck(adOpsCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

// useQuery

export const useGetPaginatedAdOpsCampaigns = ({
  filterText,
  filterHasNoLineItem,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery(
    getPaginatedAdOpsCampaignsQueryOptions({
      filterText,
      filterHasNoLineItem,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );

export const useGetPaginatedAdOpsCampaignsByAdAccount = ({
  adAccountId,
  filterText,
  filterHasNoLineItem,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery(
    getPaginatedAdOpsCampaignsByAdAccountQueryOptions({
      adAccountId,
      filterText,
      filterHasNoLineItem,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );

export const useGetAdOpsCampaignsByLineItem = (lineItemId) =>
  useQuery(getAdOpsCampaignsByLineItemQueryOptions(lineItemId));

export const useGetAdOpsCampaignsByCampaign = (campaignId) =>
  useQuery(getAdOpsCampaignsByCampaignQueryOptions(campaignId));

export const useGetAdOpsCampaign = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getAdOpsCampaignQueryOptions(id, queryClient));
};

export const useGetAdOpsCampaignStatsByGender = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByGenderQueryOptions(adOpsCampaignId));

export const useGetAdOpsCampaignStatsByAge = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByAgeQueryOptions(adOpsCampaignId));

export const useGetAdOpsCampaignStatsByPlacement = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByPlacementQueryOptions(adOpsCampaignId));

export const useGetAdOpsCampaignStatsByPlatform = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByPlatformQueryOptions(adOpsCampaignId));

export const useGetAdOpsCampaignStatsByDevicePlatform = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByDevicePlatformQueryOptions(adOpsCampaignId));

export const useGetAdOpsCampaignStatsByDevice = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByDeviceQueryOptions(adOpsCampaignId));

export const useGetAdOpsCampaignStatsByAd = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByAdQueryOptions(adOpsCampaignId));

export const useGetAdOpsCampaignStatsByAdSet = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByAdSetQueryOptions(adOpsCampaignId));

export const useGetAdOpsCampaignStatsByDay = (adOpsCampaignId) =>
  useQuery(getAdOpsCampaignStatsByDayQueryOptions(adOpsCampaignId));

// useSuspenseQuery

export const useSuspenseGetAdOpsCampaignsByLineItem = (lineItemId) =>
  useSuspenseQuery(getAdOpsCampaignsByLineItemQueryOptions(lineItemId));

export const useSuspenseGetAdOpsCampaign = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getAdOpsCampaignQueryOptions(id, queryClient));
};

export const useSuspenseGetAdOpsCampaignDataCheck = (adOpsCampaignId) =>
  useSuspenseQuery(getAdOpsCampaignDataCheckQueryOptions(adOpsCampaignId));
