const getPostBoostCampaignPaginatedQueryOptionsFromTableSettings = (
  tableSettings
) => {
  // Get paginated query options : filters, sorting and pagination
  const { globalFilter, columnFilters, sorting, pagination } = tableSettings;
  // Filters
  const filterText = globalFilter;

  const filterStartDate =
    columnFilters?.find((cf) => cf.id === "postDate")?.value.startDate || null;
  const filterEndDate =
    columnFilters?.find((cf) => cf.id === "postDate")?.value.endDate || null;

  const filterDeleted =
    columnFilters?.find((cf) => cf.id === "active")?.value === false;

  // Sorting
  const sortBy = `${sorting[0].id}-${sorting[0].desc ? "desc" : "asc"}`;
  // Pagination
  const page = pagination.pageIndex + 1;
  const nbDocsPerPage = pagination.pageSize;

  const queryOptions = {
    filterText,
    filterStartDate,
    filterEndDate,
    filterDeleted,
    sortBy,
    page,
    nbDocsPerPage,
  };

  return queryOptions;
};

export default getPostBoostCampaignPaginatedQueryOptionsFromTableSettings;
