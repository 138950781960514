import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_ROWS_PER_PAGE } from "../../constants";

const initialTableSettings = {
  // FILTERS
  globalFilter: "",
  columnFilters: [{ id: "active", value: true }],
  // PAGINATION
  pagination: {
    pageIndex: 0,
    pageSize: DEFAULT_ROWS_PER_PAGE,
  },
  // SORTING
  sorting: [{ id: "name", desc: false }],
  // ROW SELECTION
  rowSelection: {},
};

// INITIAL STATE
const initialState = {
  metrics: initialTableSettings,
  platforms: {}
};

const metricTableSettingsSlice = createSlice({
  name: "metricTableSettings",
  initialState,
  reducers: {
    // Init
    initTableSettings: (state, action) => {
      const { module, directParentId } = action.payload;
      if (!!directParentId) {
        // Init is needed only when there is a direct parent, to create an object is the state for that directParentId
        // When there is no direct parent, state.metrics is equal to initialTableSettings
        if (!state[module][directParentId]) {
          // We init table settings if its not done already
          state[module][directParentId] = initialTableSettings;
        }
      }
    },
    updateTableSettings: (state, action) => {
      const { module, directParentId, newSettings } = action.payload;
      // newSettings object structure is :
      // For global filter update : { globalFilter: "text", pagination: { pageIndex: 0, pageSize } }
      // For column filters update : { columnFilters: [], pagination: { pageIndex: 0, pageSize } }
      // For pagination update : { pagination: { pageIndex, pageSize }}
      // For sorting update : { sorting: [], pagination: { pageIndex: 0, pageSize } }
      // For row selection update : { rowSelection: { "id_1": true, "id_2": true } }
      if (!!directParentId) {
        Object.assign(state[module][directParentId], newSettings);
      } else {
        Object.assign(state.metrics, newSettings);
      }
    },
  },
});

export const {
  // INIT
  initTableSettings,
  // Update
  updateTableSettings,
} = metricTableSettingsSlice.actions;
export const metricTableSettingsReducer = metricTableSettingsSlice.reducer;
