import { NotFoundException } from "../customExceptions";
import Loader from "@material-ui/core/CircularProgress";
import trimString from "../helpers/trimString";
import { useGetLanguage } from "../queries/languages";
import { ENTITY } from "../constants";

export const LanguageBreadcrumb = ({ match }) => {
  // Entity
  const { LANGUAGE } = ENTITY;

    const {
      data: language,
      isLoading,
      isError,
      error,
    } = useGetLanguage(match.params.languageId);
  
    if (isLoading) {
      //return <span>Loading...</span>;
      return <Loader color="primary" size={18} sx={{ mr: 1 }} />;
    }
  
    if (isError && error instanceof NotFoundException) {
      return <span>Not found</span>;
    }
  
    if (language) {
      return <span>{trimString(language[LANGUAGE.nameField], 20)}</span>;
    }
  
    return null;
  };