import { useState } from "react";
import { Link, useMatch } from "@tanstack/react-router";
import PropTypes from "prop-types";
import { Box, Button, Collapse, ListItem, Tooltip } from "@material-ui/core";
import ChevronDownIcon from "../../icons/ChevronDown";
import ChevronRightIcon from "../../icons/ChevronRight";

const NavItem = ({
  children,
  depth,
  icon,
  info,
  open: openProp = false,
  path,
  title,
  isCollapsed = false,
  ...other
}) => {
  // Hooks
  const [open, setOpen] = useState(openProp);
  const match = useMatch({ from: path, shouldThrow: false });
  const active = !!match && path !== undefined;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Rendering

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0,
          minHeight: 50,
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronDownIcon fontSize="small" />
            )
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: "text.secondary",
            fontWeight: "fontWeightMedium",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: "8px",
            py: "12px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{!isCollapsed && title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf

  const jsxButton = (
    <Button
      component={path && Link}
      to={path}
      startIcon={icon}
      sx={{
        color: "text.secondary",
        fontWeight: "fontWeightMedium",
        justifyContent: "flex-start",
        textAlign: "left",
        pl: `${paddingLeft}px`,
        pr: "8px",
        py: "12px",
        textTransform: "none",
        width: "100%",
        ...(active && {
          color: "primary.main",
          fontWeight: "fontWeightBold",
          "& svg": {
            color: "primary.main",
          },
        }),
      }}
      variant="text"
    >
      <Box sx={{ flexGrow: 1 }}>{!isCollapsed && title}</Box>
      {info}
    </Button>
  );

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
        minHeight: 50,
      }}
    >
      {isCollapsed ? (
        <Tooltip
          title={title}
          placement="right"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [-150, 0],
                  },
                },
              ],
            },
          }}
        >
          {jsxButton}
        </Tooltip>
      ) : (
        jsxButton
      )}
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool,
};

export default NavItem;
