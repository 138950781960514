import * as api from "../apis/languagesApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getLanguagesQueryOptions = () =>
  queryOptions({
    queryKey: ["languages"],
    queryFn: api.getLanguages,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getLanguageQueryOptions = (id, queryClient) =>
  queryOptions({
    queryKey: ["language", id],
    queryFn: () => api.getLanguage(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["languages"])
        ?.find((language) => language.id === id),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["languages"])?.dataUpdatedAt,
  });

// useQuery

export const useGetLanguages = () => useQuery(getLanguagesQueryOptions());

export const useGetLanguage = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getLanguageQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetLanguages = () =>
  useSuspenseQuery(getLanguagesQueryOptions());

export const useSuspenseGetLanguage = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getLanguageQueryOptions(id, queryClient));
};
