import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { languageViewLoader } from "../loaders/language";
import { LanguageBreadcrumb } from "../../breadcrumbs/language";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { LANGUAGE } = ENTITY;

export const languageRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const languageRoute = createRoute({
    getParentRoute: () => parent,
    path: LANGUAGE.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: LANGUAGE.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: LANGUAGE.label.upFirstPlural,
    },
  });

  // List languages

  const languageListRoute = createRoute({
    getParentRoute: () => languageRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={LANGUAGE} />,
  }).lazy(() => import("./language.lazy").then((d) => d.LanguageListRoute));

  // Create language

  const languageCreateRoute = createRoute({
    getParentRoute: () => languageRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: LANGUAGE.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    pendingComponent: () => <EntityCreateLoading entity={LANGUAGE} />,
  }).lazy(() => import("./language.lazy").then((d) => d.LanguageCreateRoute));

  // View language

  const languageViewRootRoute = createRoute({
    getParentRoute: () => languageRoute,
    path: "$languageId",
    staticData: {
      breadcrumb: LanguageBreadcrumb,
    },
    loader: languageViewLoader,
  });

  // Edit language

  const languageEditRoute = createRoute({
    getParentRoute: () => languageViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    pendingComponent: () => <EntityEditLoading entity={LANGUAGE} />,
  }).lazy(() => import("./language.lazy").then((d) => d.LanguageEditRoute));

  // View language Layout

  const languageViewLayoutRoute = createRoute({
    getParentRoute: () => languageViewRootRoute,
    id: "language-view-layout",
    pendingComponent: () => <EntityViewLoading entity={LANGUAGE} />,
  }).lazy(() => import("./language.lazy").then((d) => d.LanguageViewLayoutRoute));

  // View language - General Tab

  const languageViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => languageViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./language.lazy").then((d) => d.LanguageViewLayoutGeneralTabRoute)
  );

  // View language - About Tab

  const languageViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => languageViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./language.lazy").then((d) => d.LanguageViewLayoutAboutTabRoute)
  );

  // Route tree
  languageRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [languageListRoute]),
    languageCreateRoute,
    languageViewRootRoute.addChildren([
      languageEditRoute,
      languageViewLayoutRoute.addChildren([
        languageViewLayoutGeneralTabRoute,
        languageViewLayoutAboutTabRoute,
      ]),
    ]),
  ]);

  return languageRoute;
};

const languageRoute = languageRouteFactory();

export default languageRoute;
