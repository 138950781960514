import Loader from "@material-ui/core/CircularProgress"

// Rendering

const Spinner = () => {
  return (
    <Loader color="primary" />
  );
};

export default Spinner;
