import React, { Suspense } from "react";
import { createRootRouteWithContext } from "@tanstack/react-router";
import { websiteRoute, homeRoute } from "./website";
import { appRoute, appHomeRoute } from "./app";
import advertiserRoute from "./app/advertiser";
import brandRoute from "./app/brand";
import campaignRoute from "./app/campaign";
import lineItemRoute from "./app/lineItem";
import adOpsCampaignRoute from "./app/adOpsCampaign";
import campaignDoctorRoute from "./app/campaignDoctor";
import campaignAgendaRoute from "./app/campaignAgenda";
import campaignAnalyticsRoute from "./app/campaignAnalytics";
import postBoostDoctorRoute from "./app/postBoostDoctor";
import userRoute from "./app/user";
import ETLTaskRoute from "./app/ETLTask";
import ETLRunRoute from "./app/ETLRun";
import adPlatformRoute from "./app/adPlatform";
import platformRoute from "./app/platform";
import metricRoute from "./app/metric";
import problemTypeRoute from "./app/problemType";
import offerRoute from "./app/offer";
import dataOriginRoute from "./app/dataOrigin";
import accountRoute from "./app/account";
import adAccountRoute from "./app/adAccount";
import postRoute from "./app/post";
import postBoostCampaignRoute from "./app/postBoostCampaign";
import specTemplateRoute from "./app/specTemplate";
import countryRoute from "./app/country";
import adFormatRoute from "./app/adFormat";
import adOpsObjectiveRoute from "./app/adOpsObjective";
import dataImportsRoute from "./app/dataImports";
import dataProceduresRoute from "./app/dataProcedures";
import languageRoute from "./app/language";
import meRoute from "./app/me";
import {
  signupRoute,
  verifyEmailRoute,
  loginRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
} from "./website/auth";
import App from "../App";
import WebsiteLayout from "../components/layouts/website/WebsiteLayout";
import NotFound from "../pages/NotFound";

// Router dev tools

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );

// Root route

export const rootRoute = createRootRouteWithContext()({
  component: () => (
    <>
      <App />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  ),
  notFoundComponent: () => (
    <WebsiteLayout>
      <NotFound origin="website" />
    </WebsiteLayout>
  ),
});

// Routes tree

export const routeTree = rootRoute.addChildren([
  websiteRoute.addChildren([
    homeRoute,
    // auth
    loginRoute,
    signupRoute,
    verifyEmailRoute,
    forgotPasswordRoute,
    resetPasswordRoute,
    // end of auth
  ]),
  appRoute.addChildren([
    appHomeRoute,
    advertiserRoute,
    brandRoute,
    campaignRoute,
    lineItemRoute,
    adOpsCampaignRoute,
    campaignDoctorRoute,
    campaignAgendaRoute,
    campaignAnalyticsRoute,
    postBoostDoctorRoute,
    adPlatformRoute,
    platformRoute,
    metricRoute,
    problemTypeRoute,
    offerRoute,
    dataOriginRoute,
    accountRoute,
    adAccountRoute,
    userRoute,
    postRoute,
    postBoostCampaignRoute,
    specTemplateRoute,
    countryRoute,
    adFormatRoute,
    adOpsObjectiveRoute,
    ETLRunRoute,
    ETLTaskRoute,
    dataImportsRoute,
    dataProceduresRoute,
    languageRoute,
    meRoute,
  ]),
]);
