import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import Spinner from "../../components/general/Spinner";

const dataProceduresRoute = createRoute({
  getParentRoute: () => appRoute,
  path: "data-procedures",
  beforeLoad: () =>
    checkPermissionsAndRedirect([
      { feature: "dataProcedures", action: "READ" },
    ]),
  staticData: {
    breadcrumb: "Data procedures",
  },
  pendingComponent: Spinner
}).lazy(() =>
  import("./dataProcedures.lazy").then((d) => d.DataProceduresRoute)
);

export default dataProceduresRoute;
