import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createLanguage = async ({ name }) => {
  const method = "POST";
  const urlAPI = "/api/languages";
  const body = {
    name
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getLanguages = async () => {
  const method = "GET";
  const urlAPI = "/api/languages";
  return await fetchServer({ method, urlAPI });
};

export const getLanguage = async (id) => {
  const method = "GET";
  const urlAPI = `/api/languages/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateLanguage = async ({ id, name}) => {
  const method = "PUT";
  const urlAPI = `/api/languages/${id}`;
  const body = { name };
  return await fetchServer({ method, urlAPI, body });
};

export const updateLanguageFlag = async ({ id, formData }) => {
  const urlAPI = `/api/languages/${id}/flag`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const deleteLanguageFlag = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/languages/${id}/flag`;
  return await fetchServer({ method, urlAPI });
};

export const deleteLanguage = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/languages/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreLanguage = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/languages/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteLanguages = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/languages/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreLanguages = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/languages/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
