import { Button, Box, Typography } from "@material-ui/core";
import Loader from "@material-ui/core/CircularProgress"


const LoadingButton = ({
  children,
  disabled = false,
  isLoading,
  loadingText,
  ...otherProps
}) => {
  
  // Rendering

  const loadingJsx = (
      <Box display="flex" alignItems="center" justifyContent="center" padding={0.25}>
        <Loader color="primary" size={18} sx={{ mr: 1 }} />
        <Typography
          color="textPrimary"
          variant="body2"
          sx={{ display: "inline" }}
        >
          {loadingText}...
        </Typography>
      </Box>
  );

  return (
    <Button disabled={isLoading ? true : disabled} {...otherProps}>
      {isLoading ? loadingJsx : children}
    </Button>
  );
};

export default LoadingButton;
