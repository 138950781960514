import fetchServer from "../helpers/api/fetchServer";
import fetchServerUpdateImage from "../helpers/api/fetchServerUpdateImage";

export const createCountry = async ({ name, nameFrench }) => {
  const method = "POST";
  const urlAPI = "/api/countries";
  const body = {
    name,
    nameFrench,
  };
  return await fetchServer({ method, urlAPI, body });
};

export const getCountries = async () => {
  const method = "GET";
  const urlAPI = "/api/countries";
  return await fetchServer({ method, urlAPI });
};

export const getCountry = async (id) => {
  const method = "GET";
  const urlAPI = `/api/countries/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const updateCountry = async ({ id, name, nameFrench }) => {
  const method = "PUT";
  const urlAPI = `/api/countries/${id}`;
  const body = { name, nameFrench };
  return await fetchServer({ method, urlAPI, body });
};

export const updateCountryFlag = async ({ id, formData }) => {
  const urlAPI = `/api/countries/${id}/flag`;
  return await fetchServerUpdateImage({ urlAPI, formData });
};

export const deleteCountryFlag = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/countries/${id}/flag`;
  return await fetchServer({ method, urlAPI });
};

export const deleteCountry = async (id) => {
  const method = "DELETE";
  const urlAPI = `/api/countries/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const restoreCountry = async (id) => {
  const method = "PUT";
  const urlAPI = `/api/countries/${id}/restore`;
  return await fetchServer({ method, urlAPI });
};

export const deleteCountries = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/countries/delete-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};

export const restoreCountries = async (arrayIds) => {
  const method = "POST";
  const urlAPI = "/api/countries/restore-many";
  const body = arrayIds;
  return await fetchServer({ method, urlAPI, body });
};
