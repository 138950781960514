import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { campaignRouteFactory } from "./campaign";
import { brandRouteFactory } from "./brand";
import { accountRouteFactory } from "./account";
import {
  advertiserListLoader,
  advertiserViewLoader,
} from "../loaders/advertiser";
import { brandListByAdvertiserLoader } from "../loaders/brand";
import { campaignListByAdvertiserLoader } from "../loaders/campaign";
import { accountListByAdvertiserLoader } from "../loaders/account";
import { AdvertiserBreadcrumb } from "../../breadcrumbs/advertiser";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityCreateLoading from "../../pages/entities/loading/EntityCreateLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { ADVERTISER, CAMPAIGN, BRAND, ACCOUNT } = ENTITY;

export const advertiserRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const advertiserRoute = createRoute({
    getParentRoute: () => parent,
    path: ADVERTISER.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADVERTISER.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ADVERTISER.label.upFirstPlural,
    },
  });

  // List advertisers

  const advertiserListRoute = createRoute({
    getParentRoute: () => advertiserRoute,
    path: "/",
    loader: advertiserListLoader,
    pendingComponent: () => <EntityListLoading entity={ADVERTISER} />,
  }).lazy(() => import("./advertiser.lazy").then((d) => d.AdvertiserListRoute));

  // Create advertiser

  const advertiserCreateRoute = createRoute({
    getParentRoute: () => advertiserRoute,
    path: "create",
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADVERTISER.type.singular, action: "CREATE" },
      ]),
    staticData: {
      breadcrumb: "Create new",
    },
    pendingComponent: () => <EntityCreateLoading entity={ADVERTISER} />,
  }).lazy(() =>
    import("./advertiser.lazy").then((d) => d.AdvertiserCreateRoute)
  );

  // View advertiser

  const advertiserViewRootRoute = createRoute({
    getParentRoute: () => advertiserRoute,
    path: "$advertiserId",
    staticData: {
      breadcrumb: AdvertiserBreadcrumb,
    },
    loader: advertiserViewLoader,
  });

  // Edit advertiser

  const advertiserEditRoute = createRoute({
    getParentRoute: () => advertiserViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    pendingComponent: () => <EntityEditLoading entity={ADVERTISER} />,
  }).lazy(() => import("./advertiser.lazy").then((d) => d.AdvertiserEditRoute));

  // View advertiser Layout

  const advertiserViewLayoutRoute = createRoute({
    getParentRoute: () => advertiserViewRootRoute,
    id: "advertiser-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ADVERTISER} />,
  }).lazy(() =>
    import("./advertiser.lazy").then((d) => d.AdvertiserViewLayoutRoute)
  );

  // View advertiser - General Tab

  const advertiserViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => advertiserViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./advertiser.lazy").then(
      (d) => d.AdvertiserViewLayoutGeneralTabRoute
    )
  );

  // View advertiser - About Tab

  const advertiserViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => advertiserViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./advertiser.lazy").then((d) => d.AdvertiserViewLayoutAboutTabRoute)
  );

  // View advertiser - Campaigns Tab

  const advertiserViewLayoutCampaignListTabRoute = createRoute({
    getParentRoute: () => advertiserViewLayoutRoute,
    path: `${CAMPAIGN.type.plural}/`,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: CAMPAIGN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: CAMPAIGN.label.upFirstPlural,
    },
    loader: campaignListByAdvertiserLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./advertiser.lazy").then(
      (d) => d.AdvertiserViewLayoutCampaignListTabRoute
    )
  );

  // View advertiser - Brands Tab

  const advertiserViewLayoutBrandListTabRoute = createRoute({
    getParentRoute: () => advertiserViewLayoutRoute,
    path: BRAND.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: BRAND.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: BRAND.label.upFirstPlural,
    },
    loader: brandListByAdvertiserLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./advertiser.lazy").then(
      (d) => d.AdvertiserViewLayoutBrandListTabRoute
    )
  );

  // View advertiser - Accounts Tab

  const advertiserViewLayoutAccountListTabRoute = createRoute({
    getParentRoute: () => advertiserViewLayoutRoute,
    path: ACCOUNT.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ACCOUNT.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ACCOUNT.label.upFirstPlural,
    },
    loader: accountListByAdvertiserLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./advertiser.lazy").then(
      (d) => d.AdvertiserViewLayoutAccountListTabRoute
    )
  );

  // Advertiser Children
  const campaignRoute = campaignRouteFactory(advertiserViewRootRoute);
  const brandRoute = brandRouteFactory(advertiserViewRootRoute);
  const accountRoute = accountRouteFactory(advertiserViewRootRoute);

  // Route tree
  advertiserRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [advertiserListRoute]),
    advertiserCreateRoute,
    advertiserViewRootRoute.addChildren([
      advertiserEditRoute,
      advertiserViewLayoutRoute.addChildren([
        advertiserViewLayoutGeneralTabRoute,
        advertiserViewLayoutAboutTabRoute,
        advertiserViewLayoutCampaignListTabRoute,
        advertiserViewLayoutBrandListTabRoute,
        advertiserViewLayoutAccountListTabRoute,
      ]),
      campaignRoute,
      brandRoute,
      accountRoute,
    ]),
  ]);

  return advertiserRoute;
};

const advertiserRoute = advertiserRouteFactory();

export default advertiserRoute;
