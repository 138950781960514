import {
  getPostBoostCampaignQueryOptions,
  getPostBoostCampaignsByPostQueryOptions,
  getPaginatedPostBoostCampaignsByAdAccountQueryOptions,
} from "../../queries/postBoostCampaigns";
import { initialTableSettings } from "../../slices/table-settings/postBoostCampaigns";
import getPostBoostCampaignPaginatedQueryOptionsFromTableSettings from "../../queries/paginated-helpers/postBoostCampaigns";

// postBoostCampaigns are pre-fetched when a user hover a 'postBoostCampaigns' link within a post
export const postBoostCampaignListByPostLoader = ({
  params,
  context: { queryClient },
}) => {
  const { postId } = params;
  queryClient.ensureQueryData(getPostBoostCampaignsByPostQueryOptions(postId));
};

// postBoostCampaigns are pre-fetched when a user hover a 'postBoostCampaigns' link within an ad account
export const postBoostCampaignListByAdAccountLoader = ({
  params,
  context: { queryClient },
}) => {
  const { adAccountId } = params;

  // Get query options : filters, sorting and pagination
  const {
    filterText,
    filterStartDate,
    filterEndDate,
    filterDeleted,
    sortBy,
    page,
    nbDocsPerPage,
  } =
    getPostBoostCampaignPaginatedQueryOptionsFromTableSettings(
      initialTableSettings
    );

  // Paginated query : postBoostCampaigns
  queryClient.ensureQueryData(
    getPaginatedPostBoostCampaignsByAdAccountQueryOptions({
      adAccountId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// postBoostCampaign is pre-fetched when a user hover a 'view postBoostCampaign' link
export const postBoostCampaignViewLoader = ({
  params,
  context: { queryClient },
}) => {
  const { postBoostCampaignId } = params;
  queryClient.ensureQueryData(
    getPostBoostCampaignQueryOptions(postBoostCampaignId, queryClient)
  );
};
