import { Box } from "@material-ui/core";
import Spinner from "../../../components/general/Spinner";
import PageWrapper from "../../../components/layouts/app/PageWrapper";

const EntityViewLoading = () => {

  // Rendering
  return (
    <PageWrapper title="Loading" hasBackButton={true} browserPageTitle="Loading">
      <Box sx={{ mt: 3 }}>
        <Spinner />
      </Box>
    </PageWrapper>
  );
};

export default EntityViewLoading;
