import {
  getPostQueryOptions,
  getPaginatedPostsByAccountQueryOptions,
  getPaginatedPostsByBrandQueryOptions,
  getPaginatedPostsByProblemTypeQueryOptions,
} from "../../queries/posts";
import { initialTableSettings } from "../../slices/table-settings/posts";
import getPostPaginatedQueryOptionsFromTableSettings from "../../queries/paginated-helpers/posts";

// posts are pre-fetched when a user hover a 'posts' link within a account
export const postListByAccountLoader = ({
  params,
  context: { queryClient },
}) => {
  const { accountId } = params;

  // Get query options : filters, sorting and pagination
  const {
    filterText,
    filterFormatPost,
    filterHasProblem,
    filterStartDate,
    filterEndDate,
    filterDeleted,
    sortBy,
    page,
    nbDocsPerPage,
  } = getPostPaginatedQueryOptionsFromTableSettings(initialTableSettings);

  // Paginated query : posts
  queryClient.ensureQueryData(
    getPaginatedPostsByAccountQueryOptions({
      accountId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// posts are pre-fetched when a user hover a 'posts' link within a brand
export const postListByBrandLoader = ({ params, context: { queryClient } }) => {
  const { brandId } = params;

  // Get query options : filters, sorting and pagination
  const {
    filterText,
    filterFormatPost,
    filterHasProblem,
    filterStartDate,
    filterEndDate,
    filterDeleted,
    sortBy,
    page,
    nbDocsPerPage,
  } = getPostPaginatedQueryOptionsFromTableSettings(initialTableSettings);

  // Paginated query : posts
  queryClient.ensureQueryData(
    getPaginatedPostsByBrandQueryOptions({
      brandId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// posts are pre-fetched when a user hover a 'posts' link within a problemType
export const postListByProblemTypeLoader = ({
  params,
  context: { queryClient },
}) => {
  const { problemTypeId } = params;

  // Get query options : filters, sorting and pagination
  const {
    filterText,
    filterFormatPost,
    filterHasProblem,
    filterStartDate,
    filterEndDate,
    filterDeleted,
    sortBy,
    page,
    nbDocsPerPage,
  } = getPostPaginatedQueryOptionsFromTableSettings(initialTableSettings);

  // Paginated query : posts
  queryClient.ensureQueryData(
    getPaginatedPostsByProblemTypeQueryOptions({
      problemTypeId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// post is pre-fetched when a user hover a 'view post' link
export const postViewLoader = ({ params, context: { queryClient } }) => {
  const { postId } = params;
  queryClient.ensureQueryData(getPostQueryOptions(postId, queryClient));
};
