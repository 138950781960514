import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../slices/auth";
import { campaignTableSettingsReducer } from "../slices/table-settings/campaigns";
import { advertiserTableSettingsReducer } from "../slices/table-settings/advertisers";
import { brandTableSettingsReducer } from "../slices/table-settings/brands";
import { platformTableSettingsReducer } from "../slices/table-settings/platforms";
import { accountTableSettingsReducer } from "../slices/table-settings/accounts";
import { lineItemTableSettingsReducer } from "../slices/table-settings/lineItems";
import { accountReportingSettingsReducer } from "../slices/account-reporting-settings";
import { accountPresentationSettingsReducer } from "../slices/account-presentation-settings";
import { postTableSettingsReducer } from "../slices/table-settings/posts";
import { dataOriginTableSettingsReducer } from "../slices/table-settings/dataOrigins";
import { userTableSettingsReducer } from "../slices/table-settings/users";
import { postBoostCampaignTableSettingsReducer } from "../slices/table-settings/postBoostCampaigns";
import { problemTypeTableSettingsReducer } from "../slices/table-settings/problemTypes";
import { ETLRunTableSettingsReducer } from "../slices/table-settings/ETLRuns";
import { ETLTaskTableSettingsReducer } from "../slices/table-settings/ETLTasks";
import { adAccountTableSettingsReducer } from "../slices/table-settings/adAccounts";
import { countryTableSettingsReducer } from "../slices/table-settings/countries";
import { languageTableSettingsReducer } from "../slices/table-settings/languages";
import { metricTableSettingsReducer } from "../slices/table-settings/metrics";
import { offerTableSettingsReducer } from "../slices/table-settings/offers";
import { lineItemPresentationSettingsReducer } from "../slices/lineItem-presentation-settings";
import { lineItemPerformanceSettingsReducer } from "../slices/lineItem-performance-settings";
import { adOpsCampaignTableSettingsReducer } from "../slices/table-settings/adOpsCampaigns";
import { adOpsCampaignPerformanceSettingsReducer } from "../slices/adOpsCampaign-performance-settings";
import { adPlatformTableSettingsReducer } from "../slices/table-settings/adPlatforms";
import { adFormatTableSettingsReducer } from "../slices/table-settings/adFormats";
import { specTemplateTableSettingsReducer } from "../slices/table-settings/specTemplates";
import { adOpsObjectiveTableSettingsReducer } from "../slices/table-settings/adOpsObjectives";
import { chatReducer } from "../slices/chat";

const store = configureStore({
  reducer: {
    auth: authReducer,
    userTableSettings: userTableSettingsReducer,
    advertiserTableSettings: advertiserTableSettingsReducer,
    platformTableSettings: platformTableSettingsReducer,
    brandTableSettings: brandTableSettingsReducer,
    campaignTableSettings: campaignTableSettingsReducer,
    accountTableSettings: accountTableSettingsReducer,
    lineItemTableSettings: lineItemTableSettingsReducer,
    accountReportingSettings: accountReportingSettingsReducer,
    accountPresentationSettings: accountPresentationSettingsReducer,
    dataOriginTableSettings: dataOriginTableSettingsReducer,
    postTableSettings: postTableSettingsReducer,
    postBoostCampaignTableSettings: postBoostCampaignTableSettingsReducer,
    problemTypeTableSettings: problemTypeTableSettingsReducer,
    ETLRunTableSettings: ETLRunTableSettingsReducer,
    ETLTaskTableSettings: ETLTaskTableSettingsReducer,
    adAccountTableSettings: adAccountTableSettingsReducer,
    countryTableSettings: countryTableSettingsReducer,
    metricTableSettings: metricTableSettingsReducer,
    offerTableSettings: offerTableSettingsReducer,
    lineItemPresentationSettings: lineItemPresentationSettingsReducer,
    lineItemPerformanceSettings: lineItemPerformanceSettingsReducer,
    adOpsCampaignTableSettings: adOpsCampaignTableSettingsReducer,
    adOpsCampaignPerformanceSettings: adOpsCampaignPerformanceSettingsReducer,
    adPlatformTableSettings: adPlatformTableSettingsReducer,
    adFormatTableSettings: adFormatTableSettingsReducer,
    specTemplateTableSettings: specTemplateTableSettingsReducer,
    adOpsObjectiveTableSettings: adOpsObjectiveTableSettingsReducer,
    languageTableSettings: languageTableSettingsReducer,
    chat: chatReducer,
  },
});

export default store;
