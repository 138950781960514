import fetchServer from "../helpers/api/fetchServer";

export const getPaginatedPosts = async ({
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = "/api/posts?";
  // Filters
  if (filterText) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `text=${filterText}`;
  }
  if (filterFormatPost) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `format=${filterFormatPost}`;
  }
  if (filterHasProblem) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `&hasProblem=${filterHasProblem}`;
  }
  if (filterStartDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `startDate=${filterStartDate}`;
  }
  if (filterEndDate) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `endDate=${filterEndDate}`;
  }
  if (filterDeleted) {
    if (urlAPI.slice(-1) !== "?") {
      urlAPI += "&";
    }
    urlAPI += `deleted=${filterDeleted}`;
  }
  // Sort by
  if (urlAPI.slice(-1) !== "?") {
    urlAPI += "&";
  }

  urlAPI += `sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPaginatedPostsByAccount = async ({
  accountId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = `/api/posts?account=${accountId}`;
  // Filters
  if (filterText) {
    urlAPI += `&text=${filterText}`;
  }
  if (filterFormatPost) {
    urlAPI += `&format=${filterFormatPost}`;
  }
  if (filterHasProblem) {
    urlAPI += `&hasProblem=${filterHasProblem}`;
  }
  if (filterStartDate) {
    urlAPI += `&startDate=${filterStartDate}`;
  }
  if (filterEndDate) {
    urlAPI += `&endDate=${filterEndDate}`;
  }
  if (filterDeleted) {
    urlAPI += `&deleted=${filterDeleted}`;
  }
  // Sort by
  urlAPI += `&sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPaginatedPostsByBrand = async ({
  brandId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = `/api/posts?brand=${brandId}`;
  // Filters
  if (filterText) {
    urlAPI += `&text=${filterText}`;
  }
  if (filterFormatPost) {
    urlAPI += `&format=${filterFormatPost}`;
  }
  if (filterHasProblem) {
    urlAPI += `&hasProblem=${filterHasProblem}`;
  }
  if (filterStartDate) {
    urlAPI += `&startDate=${filterStartDate}`;
  }
  if (filterEndDate) {
    urlAPI += `&endDate=${filterEndDate}`;
  }
  if (filterDeleted) {
    urlAPI += `&deleted=${filterDeleted}`;
  }
  // Sort by
  urlAPI += `&sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPaginatedPostsByProblemType = async ({
  problemTypeId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  const method = "GET";
  let urlAPI = `/api/posts?problemType=${problemTypeId}`;
  // Filters
  if (filterText) {
    urlAPI += `&text=${filterText}`;
  }
  if (filterFormatPost) {
    urlAPI += `&format=${filterFormatPost}`;
  }
  if (filterHasProblem) {
    urlAPI += `&hasProblem=${filterHasProblem}`;
  }
  if (filterStartDate) {
    urlAPI += `&startDate=${filterStartDate}`;
  }
  if (filterEndDate) {
    urlAPI += `&endDate=${filterEndDate}`;
  }
  if (filterDeleted) {
    urlAPI += `&deleted=${filterDeleted}`;
  }
  // Sort by
  urlAPI += `&sortBy=${sortBy}`;

  // Pagination
  urlAPI += `&page=${page}&nbDocsPerPage=${nbDocsPerPage}`;

  return await fetchServer({ method, urlAPI });
};

export const getPost = async (id) => {
  const method = "GET";
  const urlAPI = `/api/posts/${id}`;
  return await fetchServer({ method, urlAPI });
};

export const getPostPaidStatsByDay = async (id) => {
  const method = "GET";
  const urlAPI = `/api/posts/${id}/paidStatsByDay`;
  return await fetchServer({ method, urlAPI });
};

export const updatePost = async ({
  id,
  brandId,
  problemTypeId,
  hasNoBrand,
}) => {
  const method = "PUT";
  const urlAPI = `/api/posts/${id}`;
  const body = hasNoBrand
    ? {
        hasNoBrand,
        problemTypeId,
      }
    : {
        brandId,
        problemTypeId,
      };

  return await fetchServer({ method, urlAPI, body });
};

export const updatePostBrand = async ({ id, brandId, hasNoBrand }) => {
  const method = "PUT";
  const urlAPI = `/api/posts/${id}/brand`;
  const body = hasNoBrand
    ? {
        hasNoBrand,
      }
    : {
        brandId,
      };
  return await fetchServer({ method, urlAPI, body });
};

export const updatePostProblemType = async ({ id, problemTypeId }) => {
  const method = "PUT";
  const urlAPI = `/api/posts/${id}/problemType`;
  const body = { problemTypeId };

  return await fetchServer({ method, urlAPI, body });
};

export const updatePostWait48HoursForSpent = async ({ id }) => {
  const method = "PUT";
  const urlAPI = `/api/posts/${id}/wait-48-hours-for-spent`;
  const body = {};

  return await fetchServer({ method, urlAPI, body });
};
