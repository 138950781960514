import {
  getAdOpsCampaignQueryOptions,
  getAdOpsCampaignsByLineItemQueryOptions,
  getPaginatedAdOpsCampaignsByAdAccountQueryOptions
} from "../../queries/adOpsCampaigns";
import { initialTableSettings } from "../../slices/table-settings/adOpsCampaigns";
import getAdOpsCampaignPaginatedQueryOptionsFromTableSettings from "../../queries/paginated-helpers/adOpsCampaigns";

// adOpsCampaigns are pre-fetched when a user hover a 'adOpsCampaigns' link within a lineItem
export const adOpsCampaignsListByLineItemLoader = ({
  params,
  context: { queryClient },
}) => {
  const { lineItemId } = params;
  queryClient.ensureQueryData(
    getAdOpsCampaignsByLineItemQueryOptions(lineItemId)
  );
};

// adOpsCampaigns are pre-fetched when a user hover a 'adOpsCampaigns' link within an ad account
export const adOpsCampaignListByAdAccountLoader = ({
  params,
  context: { queryClient },
}) => {
  const { adAccountId } = params;

  // Get query options : filters, sorting and pagination
  const { filterText, filterDeleted, sortBy, page, nbDocsPerPage } =
    getAdOpsCampaignPaginatedQueryOptionsFromTableSettings(
      initialTableSettings
    );

  // Paginated query : postBoostCampaigns
  queryClient.ensureQueryData(
    getPaginatedAdOpsCampaignsByAdAccountQueryOptions({
      adAccountId,
      filterText,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

// adOpsCampaign is pre-fetched when a user hover a 'view adOpsCampaign' link
export const adOpsCampaignViewLoader = ({
  params,
  context: { queryClient },
}) => {
  const { adOpsCampaignId } = params;
  queryClient.ensureQueryData(
    getAdOpsCampaignQueryOptions(adOpsCampaignId, queryClient)
  );
};
