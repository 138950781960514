import { useLocation } from "@tanstack/react-router";
import { Box } from "@material-ui/core";
import Spinner from "../../../components/general/Spinner";
import PageWrapper from "../../../components/layouts/app/PageWrapper";

const EntityEditLoading = () => {
  // Hooks
  const location = useLocation();

  // Header Content
  const hasCancelButtonUrl = location.pathname.substring(
    0,
    location.pathname.search("edit")
  );

  // Rendering
  return (
    <PageWrapper
      title="Loading"
      hasCancelButton={hasCancelButtonUrl}
      browserPageTitle="Loading"
    >
      <Box sx={{ mt: 3 }}>
        <Spinner />
      </Box>
    </PageWrapper>
  );
};

export default EntityEditLoading;
