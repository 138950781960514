import { createRoute } from "@tanstack/react-router";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { postBoostCampaignRouteFactory } from "./postBoostCampaign";
import { adOpsCampaignRouteFactory } from "./adOpsCampaign";
import { postViewLoader } from "../loaders/post";
import { postBoostCampaignListByPostLoader } from "../loaders/postBoostCampaign";
import { PostBreadcrumb } from "../../breadcrumbs/post";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";
import EntityEditLoading from "../../pages/entities/loading/EntityEditLoading";

const { POST, POSTBOOSTCAMPAIGN } = ENTITY;

export const postRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const postRoute = createRoute({
    getParentRoute: () => parent,
    path: POST.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: POST.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: POST.label.upFirstPlural,
    },
  });

  // List posts

  const postListRoute = createRoute({
    getParentRoute: () => postRoute,
    path: "/",
    pendingComponent: () => <EntityListLoading entity={POST} />,
  }).lazy(() => import("./post.lazy").then((d) => d.PostListRoute));

  // View post

  const postViewRootRoute = createRoute({
    getParentRoute: () => postRoute,
    path: "$postId",
    staticData: {
      breadcrumb: PostBreadcrumb,
    },
    loader: postViewLoader,
  });

  // Edit post

  const postEditRoute = createRoute({
    getParentRoute: () => postViewRootRoute,
    path: "edit",
    staticData: {
      breadcrumb: "Edit",
    },
    pendingComponent: () => <EntityEditLoading entity={POST} />,
  }).lazy(() => import("./post.lazy").then((d) => d.PostEditRoute));

  // View post Layout

  const postViewLayoutRoute = createRoute({
    getParentRoute: () => postViewRootRoute,
    id: "post-view-layout",
    pendingComponent: () => <EntityViewLoading entity={POST} />,
  }).lazy(() => import("./post.lazy").then((d) => d.PostViewLayoutRoute));

  // View post - General Tab

  const postViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => postViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./post.lazy").then((d) => d.PostViewLayoutGeneralTabRoute)
  );

  // View post - Performance Tab

  const postViewLayoutPerformanceTabRoute = createRoute({
    getParentRoute: () => postViewLayoutRoute,
    path: "performance",
    staticData: {
      breadcrumb: "Performance",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./post.lazy").then((d) => d.PostViewLayoutPerformanceTabRoute)
  );

  // View post - About Tab

  const postViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => postViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./post.lazy").then((d) => d.PostViewLayoutAboutTabRoute)
  );

  // View post - PostBoostCampaigns Tab

  const postViewLayoutPostBoostCampaignListTabRoute = createRoute({
    getParentRoute: () => postViewLayoutRoute,
    path: POSTBOOSTCAMPAIGN.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: POSTBOOSTCAMPAIGN.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: POSTBOOSTCAMPAIGN.label.upFirstPlural,
    },
    loader: postBoostCampaignListByPostLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./post.lazy").then(
      (d) => d.PostViewLayoutPostBoostCampaignListTabRoute
    )
  );

  // Post Children
  const postBoostCampaignRoute =
    postBoostCampaignRouteFactory(postViewRootRoute);
  const adOpsCampaignRoute = adOpsCampaignRouteFactory(postViewRootRoute);

  // Route tree
  postRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [postListRoute]),
    postViewRootRoute.addChildren([
      postEditRoute,
      postViewLayoutRoute.addChildren([
        postViewLayoutGeneralTabRoute,
        postViewLayoutPerformanceTabRoute,
        postViewLayoutAboutTabRoute,
        postViewLayoutPostBoostCampaignListTabRoute,
      ]),
      postBoostCampaignRoute,
      adOpsCampaignRoute
    ]),
  ]);

  return postRoute;
};

const postRoute = postRouteFactory();

export default postRoute;
