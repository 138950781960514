import * as api from "../apis/postsApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getPaginatedPostsQueryOptions = ({
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return queryOptions({
    queryKey: [
      "posts",
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedPosts({
        filterText,
        filterFormatPost,
        filterHasProblem,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });
};

export const getPaginatedPostsByAccountQueryOptions = ({
  accountId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return queryOptions({
    queryKey: [
      "account.posts",
      accountId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedPostsByAccount({
        accountId,
        filterText,
        filterFormatPost,
        filterHasProblem,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });
};

export const getPaginatedPostsByBrandQueryOptions = ({
  brandId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return queryOptions({
    queryKey: [
      "brand.posts",
      brandId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedPostsByBrand({
        brandId,
        filterText,
        filterFormatPost,
        filterHasProblem,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });
};

export const getPaginatedPostsByProblemTypeQueryOptions = ({
  problemTypeId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return queryOptions({
    queryKey: [
      "problemType.posts",
      problemTypeId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedPostsByProblemType({
        problemTypeId,
        filterText,
        filterFormatPost,
        filterHasProblem,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });
};

export const getPostQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["post", id],
    queryFn: () => api.getPost(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // we try to find the post in one of the "posts" queries
      let found;

      queryClient.getQueriesData({ queryKey: ["posts"] }).forEach((query) => {
        const data = query[1]?.data;
        if (data) {
          data.forEach((post) => {
            if (post.id === id) {
              found = post;
              queryKey = query[0];
            }
          });
        }
      });

      // otherwise, we try to find the post in one of the "account.posts" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["account.posts"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((post) => {
                if (post.id === id) {
                  found = post;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      // otherwise, we try to find the post in one of the "brand.posts" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["brand.posts"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((post) => {
                if (post.id === id) {
                  found = post;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      // otherwise, we try to find the post in one of the "dataOrigin.posts" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["dataOrigin.posts"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((post) => {
                if (post.id === id) {
                  found = post;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      // otherwise, we try to find the post in one of the "problemType.posts" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["problemType.posts"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((post) => {
                if (post.id === id) {
                  found = post;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

const getPostPaidStatsByDayQueryOptions = (postId) =>
  queryOptions({
    queryKey: ["post.paidStatsByDay", postId],
    queryFn: () => api.getPostPaidStatsByDay(postId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

// useQuery

export const useGetPaginatedPosts = ({
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery(
    getPaginatedPostsQueryOptions({
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );

export const useGetPaginatedPostsByAccount = ({
  accountId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) => {
  return useQuery(
    getPaginatedPostsByAccountQueryOptions({
      accountId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );
};

export const useGetPaginatedPostsByBrand = ({
  brandId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery(
    getPaginatedPostsByBrandQueryOptions({
      brandId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );

export const useGetPaginatedPostsByProblemType = ({
  problemTypeId,
  filterText,
  filterFormatPost,
  filterHasProblem,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery(
    getPaginatedPostsByProblemTypeQueryOptions({
      problemTypeId,
      filterText,
      filterFormatPost,
      filterHasProblem,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );

export const useGetPost = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getPostQueryOptions(id, queryClient));
};

export const useGetPostPaidStatsByDay = (postId) =>
  useQuery(getPostPaidStatsByDayQueryOptions(postId));

// useSuspenseQuery

export const useSuspenseGetPost = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getPostQueryOptions(id, queryClient));
};
