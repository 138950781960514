import { createRoute } from "@tanstack/react-router";
import { appRoute } from "./index";
import { ENTITY } from "../../constants";
import { checkPermissionsAndRedirect } from "../../helpers/checkPermissions";
import { adAccountRouteFactory } from "./adAccount";
import { platformRouteFactory } from "./platform";
import {
  adPlatformListLoader,
  adPlatformViewLoader,
} from "../loaders/adPlatform";
import { adAccountListByAdPlatformLoader } from "../loaders/adAccount";
import { platformListByAdPlatformLoader } from "../loaders/platform";
import { AdPlatformBreadcrumb } from "../../breadcrumbs/adPlatform";
import Spinner from "../../components/general/Spinner";
// import loading pages
import EntityListLoading from "../../pages/entities/loading/EntityListLoading";
import EntityViewLoading from "../../pages/entities/loading/EntityViewLoading";

const { ADPLATFORM, ADACCOUNT, PLATFORM } = ENTITY;

export const adPlatformRouteFactory = (parent = appRoute) => {
  // If parent route is appRoute => the route is NOT nested (ie. its directly under appRoute)
  // If parent route is NOT appRoute => route is nested
  const isNestedRoute = parent !== appRoute;

  // Root

  const adPlatformRoute = createRoute({
    getParentRoute: () => parent,
    path: ADPLATFORM.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADPLATFORM.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ADPLATFORM.label.upFirstPlural,
    },
  });

  // List adPlatforms

  const adPlatformListRoute = createRoute({
    getParentRoute: () => adPlatformRoute,
    path: "/",
    loader: adPlatformListLoader,
    pendingComponent: () => <EntityListLoading entity={ADPLATFORM} />,
  }).lazy(() => import("./adPlatform.lazy").then((d) => d.AdPlatformListRoute));

  // View adPlatform

  const adPlatformViewRootRoute = createRoute({
    getParentRoute: () => adPlatformRoute,
    path: "$adPlatformId",
    staticData: {
      breadcrumb: AdPlatformBreadcrumb,
    },
    loader: adPlatformViewLoader,
  });

  // View adPlatform Layout

  const adPlatformViewLayoutRoute = createRoute({
    getParentRoute: () => adPlatformViewRootRoute,
    id: "adPlatform-view-layout",
    pendingComponent: () => <EntityViewLoading entity={ADPLATFORM} />,
  }).lazy(() =>
    import("./adPlatform.lazy").then((d) => d.AdPlatformViewLayoutRoute)
  );

  // View adPlatform - General Tab

  const adPlatformViewLayoutGeneralTabRoute = createRoute({
    getParentRoute: () => adPlatformViewLayoutRoute,
    path: "/",
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adPlatform.lazy").then(
      (d) => d.AdPlatformViewLayoutGeneralTabRoute
    )
  );

  // View adPlatform - About Tab

  const adPlatformViewLayoutAboutTabRoute = createRoute({
    getParentRoute: () => adPlatformViewLayoutRoute,
    path: "about",
    staticData: {
      breadcrumb: "About",
    },
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adPlatform.lazy").then((d) => d.AdPlatformViewLayoutAboutTabRoute)
  );

  // View adPlatform - AdAccounts Tab

  const adPlatformViewLayoutAdAccountListTabRoute = createRoute({
    getParentRoute: () => adPlatformViewLayoutRoute,
    path: ADACCOUNT.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: ADACCOUNT.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: ADACCOUNT.label.upFirstPlural,
    },
    loader: adAccountListByAdPlatformLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adPlatform.lazy").then(
      (d) => d.AdPlatformViewLayoutAdAccountListTabRoute
    )
  );

  // View adPlatform - Platforms Tab

  const adPlatformViewLayoutPlatformListTabRoute = createRoute({
    getParentRoute: () => adPlatformViewLayoutRoute,
    path: PLATFORM.type.plural,
    beforeLoad: () =>
      checkPermissionsAndRedirect([
        { feature: PLATFORM.type.singular, action: "READ" },
      ]),
    staticData: {
      breadcrumb: PLATFORM.label.upFirstPlural,
    },
    loader: platformListByAdPlatformLoader,
    pendingComponent: Spinner,
  }).lazy(() =>
    import("./adPlatform.lazy").then(
      (d) => d.AdPlatformViewLayoutPlatformListTabRoute
    )
  );

  // AdPlatform Children
  const adAccountRoute = adAccountRouteFactory(adPlatformViewRootRoute);
  const platformRoute = platformRouteFactory(adPlatformViewRootRoute);
  

  // Route tree
  adPlatformRoute.addChildren([
    // if the route is nested within a parent route, we do not provide ListRoute
    // because the route is already nested in a TabListRoute
    ...(isNestedRoute ? [] : [adPlatformListRoute]),
    adPlatformViewRootRoute.addChildren([
      adPlatformViewLayoutRoute.addChildren([
        adPlatformViewLayoutGeneralTabRoute,
        adPlatformViewLayoutAboutTabRoute,
        adPlatformViewLayoutAdAccountListTabRoute,
        adPlatformViewLayoutPlatformListTabRoute,
      ]),
      adAccountRoute,
      platformRoute
    ]),
  ]);

  return adPlatformRoute;
};

const adPlatformRoute = adPlatformRouteFactory();

export default adPlatformRoute;
