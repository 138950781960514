import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import Spinner from "../../../components/general/Spinner"
import PageWrapper from "../../../components/layouts/app/PageWrapper";

const EntityListLoading = ({ entity }) => {
  // Get user permissions
  const { currentUser } = useSelector((state) => state.auth);
  const userPermissions = currentUser.permissions.find(
    (f) => f.feature === entity.type.singular
  );

  // Rendering

  return (
    <PageWrapper
      title={entity.label.upFirstPlural}
      // We add a Create button only if the entity can be created (some entities are created only by ETL)
      // AND the user has the permission to create the entity
      hasAddButton={entity.canCreate && userPermissions?.canCreate}
      browserPageTitle={entity.label.upFirstPlural}
    >
      <Box sx={{ mt: 3 }}>
        <Spinner />
      </Box>
    </PageWrapper>
  );
};

export default EntityListLoading;
