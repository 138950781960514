import AppBreadcrumbs from "./AppBreadcrumbs";
import { Link as RouterLink } from "@tanstack/react-router";
import { Avatar, Box, Button, Grid, Typography } from "@material-ui/core";
import PlusIcon from "../../../icons/Plus";
import PencilAltIcon from "../../../icons/PencilAlt";
import Loader from "@material-ui/core/CircularProgress"
import BackButton from "../../general/BackButton";

const PageHeader = ({
  title,
  avatar: { url, name },
  hasCancelButton = false,
  hasBackButton = false,
  hasAddButton = false,
  editButtonUrl = "",
}) => {
  // Hooks

  const hasEditButton = !!editButtonUrl

  const hasButton =
    hasCancelButton || hasBackButton || hasAddButton || hasEditButton;

  // Rendering

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      {/* Page title */}
      <Grid item>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
          }}
        >
          {!!url && (
            <Avatar
              src={url}
              sx={{
                height: 28,
                width: 28,
                mr: 1,
              }}
            >
              <Typography variant="h6">{name[0]}</Typography>
            </Avatar>
          )}
          <Typography color="textPrimary" variant="h5">
            {title === "Loading" ? (
              <Loader color="primary" size={26} sx={{ mr: 1 }} />
            ) : (
              title
            )}
          </Typography>
        </Box>
        <AppBreadcrumbs />
      </Grid>

      {hasButton && (
        <Grid item>
          <Box sx={{ m: -1 }}>
            {hasAddButton && (
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<PlusIcon fontSize="small" />}
                sx={{ m: 1 }}
                to={"create"}
                variant="contained"
              >
                Create new
              </Button>
            )}
            {hasEditButton && (
              <Button
                color="primary"
                component={RouterLink}
                startIcon={<PencilAltIcon fontSize="small" />}
                sx={{ m: 1 }}
                to={editButtonUrl}
                variant="contained"
              >
                Edit
              </Button>
            )}
            {hasBackButton && <BackButton />}
            {hasCancelButton && <BackButton label="Cancel" />}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeader;
