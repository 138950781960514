import * as api from "../apis/postBoostCampaignsApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getPaginatedPostBoostCampaignsQueryOptions = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "postBoostCampaigns",
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedPostBoostCampaigns({
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPaginatedPostBoostCampaignsByAdAccountQueryOptions = ({
  adAccountId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "adAccount.postBoostCampaigns",
      adAccountId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedPostBoostCampaignsByAdAccount({
        adAccountId,
        filterText,
        filterStartDate,
        filterEndDate,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPostBoostCampaignsByPostQueryOptions = (postId) =>
  queryOptions({
    queryKey: ["post.postBoostCampaigns", postId],
    queryFn: () => api.getPostBoostCampaignsByPost(postId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getPostBoostCampaignQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["postBoostCampaign", id],
    queryFn: () => api.getPostBoostCampaign(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // we try to find the postBoostCampaign in one of the "postBoostCampaigns" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["postBoostCampaigns"] })
        .forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((postBoostCampaign) => {
              if (postBoostCampaign.id === id) {
                found = postBoostCampaign;
                queryKey = query[0];
              }
            });
          }
        });

      // otherwise, we try to find the postBoostCampaign in one of the "post.postBoostCampaigns" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["post.postBoostCampaigns"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((postBoostCampaign) => {
                if (postBoostCampaign.id === id) {
                  found = postBoostCampaign;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      // otherwise, we try to find the postBoostCampaign in one of the "adAccount.postBoostCampaigns" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["adAccount.postBoostCampaigns"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((postBoostCampaign) => {
                if (postBoostCampaign.id === id) {
                  found = postBoostCampaign;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

const getPostBoostCampaignStatsByDayQueryOptions = (postBoostCampaignId) =>
  queryOptions({
    queryKey: ["postBoostCampaign.statsByDay", postBoostCampaignId],
    queryFn: () => api.getPostBoostCampaignStatsByDay(postBoostCampaignId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

// useQuery

export const useGetPaginatedPostBoostCampaigns = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery(
    getPaginatedPostBoostCampaignsQueryOptions({
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );

export const useGetPaginatedPostBoostCampaignsByAdAccount = ({
  adAccountId,
  filterText,
  filterStartDate,
  filterEndDate,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery(
    getPaginatedPostBoostCampaignsByAdAccountQueryOptions({
      adAccountId,
      filterText,
      filterStartDate,
      filterEndDate,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );

export const useGetPostBoostCampaign = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getPostBoostCampaignQueryOptions(id, queryClient));
};

export const useGetPostBoostCampaignStatsByDay = (postBoostCampaignId) => {
  return useQuery(
    getPostBoostCampaignStatsByDayQueryOptions(postBoostCampaignId)
  );
};

// useSuspenseQuery

export const useSuspenseGetPostBoostCampaignsByPost = (postId) =>
  useSuspenseQuery(getPostBoostCampaignsByPostQueryOptions(postId));

export const useSuspenseGetPostBoostCampaign = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getPostBoostCampaignQueryOptions(id, queryClient));
};
