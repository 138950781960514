import * as api from "../apis/ETLTasksApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getPaginatedETLTasksQueryOptions = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterIsCompleted,
  filterHasError,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  queryOptions({
    queryKey: [
      "ETLTasks",
      filterText,
      filterStartDate,
      filterEndDate,
      filterIsCompleted,
      filterHasError,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    ],
    queryFn: () =>
      api.getPaginatedETLTasks({
        filterText,
        filterStartDate,
        filterEndDate,
        filterIsCompleted,
        filterHasError,
        filterDeleted,
        sortBy,
        page,
        nbDocsPerPage,
      }),
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getETLTasksByETLRunQueryOptions = (ETLRunId) =>
  queryOptions({
    queryKey: ["ETLRun.ETLTasks", ETLRunId],
    queryFn: () => api.getETLTasksByETLRun(ETLRunId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getETLTaskQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["ETLTask", id],
    queryFn: () => api.getETLTask(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // we try to find the ETLTask in one of the "ETLTasks" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["ETLTasks"] })
        .forEach((query) => {
          const data = query[1]?.data;
          if (data) {
            data.forEach((ETLTask) => {
              if (ETLTask.id === id) {
                found = ETLTask;
                queryKey = query[0];
              }
            });
          }
        });

      // otherwise, we try to find the ETLTask in one of the "ETLRun.ETLTasks" queries

      if (!found) {
        queryClient
          .getQueriesData({ queryKey: ["ETLRun.ETLTasks"] })
          .forEach((query) => {
            const data = query[1]?.data;
            if (data) {
              data.forEach((ETLTask) => {
                if (ETLTask.id === id) {
                  found = ETLTask;
                  queryKey = query[0];
                }
              });
            }
          });
      }

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetPaginatedETLTasks = ({
  filterText,
  filterStartDate,
  filterEndDate,
  filterIsCompleted,
  filterHasError,
  filterDeleted,
  sortBy,
  page,
  nbDocsPerPage,
}) =>
  useQuery(
    getPaginatedETLTasksQueryOptions({
      filterText,
      filterStartDate,
      filterEndDate,
      filterIsCompleted,
      filterHasError,
      filterDeleted,
      sortBy,
      page,
      nbDocsPerPage,
    })
  );

export const useGetETLTask = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getETLTaskQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetETLTasksByETLRun = (ETLRunId) =>
  useSuspenseQuery(getETLTasksByETLRunQueryOptions(ETLRunId));

export const useSuspenseGetETLTask = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getETLTaskQueryOptions(id, queryClient));
};
