import * as api from "../apis/platformsApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getPlatformsQueryOptions = () => {
  return queryOptions({
    queryKey: ["platforms"],
    queryFn: api.getPlatforms,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });
};

export const getPlatformsByAdPlatformQueryOptions = (
  adPlatformId,
  queryClient
) =>
  queryOptions({
    queryKey: ["adPlatform.platforms", adPlatformId],
    queryFn: () => api.getPlatformsByAdPlatform(adPlatformId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["platforms"])
        ?.filter((platform) => platform.adPlatform?.id === adPlatformId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["platforms"])?.dataUpdatedAt,
  });

export const getPlatformQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["platform", id],
    queryFn: () => api.getPlatform(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "platforms" query exist, we use it to init data
      if (queryClient.getQueryState(["platforms"])) {
        queryKey = ["platforms"];
        return queryClient
          .getQueryData(["platforms"])
          ?.find((platform) => platform.id === id);
      }

      // otherwise, we try to find the platform in one of the "adPlatform.platforms" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["adPlatform.platforms"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((platform) => {
            if (platform.id === id) {
              found = platform;
              queryKey = query[0];
            }
          });
        });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetPlatforms = () => useQuery(getPlatformsQueryOptions());

export const useGetPlatform = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getPlatformQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetPlatforms = () => {
  return useSuspenseQuery(getPlatformsQueryOptions());
};

export const useSuspenseGetPlatformsByAdPlatform = (adPlatformId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getPlatformsByAdPlatformQueryOptions(adPlatformId, queryClient)
  );
};

export const useSuspenseGetPlatform = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getPlatformQueryOptions(id, queryClient));
};
