import { createSlice } from "@reduxjs/toolkit";

const initialSettings = {
  activeSlide: 1,
  referenceLineItemId: "",
};

// INITIAL STATE
const initialState = {
  lineItems: [],
};

const lineItemPresentationSettingsSlice = createSlice({
  name: "lineItemPresentationSettings",
  initialState,
  reducers: {
    setActiveSlide: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.presentationSettings,
            activeSlide: action.payload.activeSlide,
          };
          return { ...lineItem, presentationSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setReferenceLineItemId: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.presentationSettings,
            referenceLineItemId: action.payload.referenceLineItemId,
          };
          return { ...lineItem, presentationSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setBarometerMetricId: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.presentationSettings,
            barometerMetricId: action.payload.barometerMetricId,
          };
          return { ...lineItem, presentationSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setBarometerDateRangeId: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.presentationSettings,
            barometerDateRangeId: action.payload.barometerDateRangeId,
          };
          return { ...lineItem, presentationSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setBarometerModeId: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.presentationSettings,
            barometerModeId: action.payload.barometerModeId,
          };
          return { ...lineItem, presentationSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setShowLineItemsWithInvalidStats: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.presentationSettings,
            showLineItemsWithInvalidStats:
              action.payload.showLineItemsWithInvalidStats,
          };
          return { ...lineItem, presentationSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    setShowLineItemsFromLiveCampaigns: (state, action) => {
      state.lineItems = state.lineItems.map((lineItem) => {
        if (lineItem.id === action.payload.id) {
          const updatedSettings = {
            ...lineItem.presentationSettings,
            showLineItemsFromLiveCampaigns:
              action.payload.showLineItemsFromLiveCampaigns,
          };
          return { ...lineItem, presentationSettings: updatedSettings };
        } else {
          return lineItem;
        }
      });
    },
    initPresentationSettingsSet: (state, action) => {
      if (
        !state.lineItems.find((lineItem) => lineItem.id === action.payload.id)
      ) {
        state.lineItems.push({
          id: action.payload.id,
          presentationSettings: initialSettings,
        });
      }
    },
  },
});

export const {
  setActiveSlide,
  setReferenceLineItemId,
  setBarometerMetricId,
  setBarometerDateRangeId,
  setBarometerModeId,
  setShowLineItemsWithInvalidStats,
  setShowLineItemsFromLiveCampaigns,
  initPresentationSettingsSet,
} = lineItemPresentationSettingsSlice.actions;
export const lineItemPresentationSettingsReducer =
  lineItemPresentationSettingsSlice.reducer;
