import {
  getLineItemQueryOptions,
  getLineItemsByCampaignQueryOptions,
  getLineItemsByOfferQueryOptions,
} from "../../queries/lineItems";
import { getAdvertisersQueryOptions } from "../../queries/advertisers";
import { getCampaignsQueryOptions } from "../../queries/campaigns";
import { getCountriesQueryOptions } from "../../queries/countries";
import { getPlatformsQueryOptions } from "../../queries/platforms";
import { getOffersQueryOptions } from "../../queries/offers";
import { getMetricsQueryOptions } from "../../queries/metrics";

// necessary entities are pre-fetched when a user hover a 'create lineItem' or a 'edit lineItem' link
export const lineItemFormLoader = ({ context: { queryClient } }) => {
  queryClient.ensureQueryData(getAdvertisersQueryOptions());
  queryClient.ensureQueryData(getCampaignsQueryOptions());
  queryClient.ensureQueryData(getCountriesQueryOptions());
  queryClient.ensureQueryData(getPlatformsQueryOptions());
  queryClient.ensureQueryData(getOffersQueryOptions());
  queryClient.ensureQueryData(getMetricsQueryOptions());
};

// lineItems are pre-fetched when a user hover a 'lineItems' link within a campaign
export const lineItemListByCampaignLoader = ({
  params,
  context: { queryClient },
}) => {
  const { campaignId } = params;
  queryClient.ensureQueryData(
    getLineItemsByCampaignQueryOptions(campaignId)
  );
};

// lineItems are pre-fetched when a user hover a 'lineItems' link within an offer
export const lineItemListByOfferLoader = ({
  params,
  context: { queryClient },
}) => {
  const { offerId } = params;
  queryClient.ensureQueryData(
    getLineItemsByOfferQueryOptions(offerId)
  );
};

// lineItem is pre-fetched when a user hover a 'view lineItem' link
export const lineItemViewLoader = ({ params, context: { queryClient } }) => {
  const { lineItemId } = params;
  queryClient.ensureQueryData(getLineItemQueryOptions(lineItemId, queryClient));
};
