import * as api from "../apis/offersApi";
import {
  useQuery,
  useQueryClient,
  queryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { STALE_TIME } from "../constants";

// Query options

export const getOffersQueryOptions = () =>
  queryOptions({
    queryKey: ["offers"],
    queryFn: api.getOffers,
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
  });

export const getOffersByPlatformQueryOptions = (platformId, queryClient) =>
  queryOptions({
    queryKey: ["platform.offers", platformId],
    queryFn: () => api.getOffersByPlatform(platformId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["offers"])
        ?.filter((offer) => offer.platform.id === platformId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["offers"])?.dataUpdatedAt,
  });

export const getOffersByCountryQueryOptions = (countryId, queryClient) =>
  queryOptions({
    queryKey: ["country.offers", countryId],
    queryFn: () => api.getOffersByCountry(countryId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["offers"])
        ?.filter((offer) => offer.country.id === countryId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["offers"])?.dataUpdatedAt,
  });

export const getOffersByAdFormatQueryOptions = (adFormatId, queryClient) =>
  queryOptions({
    queryKey: ["adFormat.offers", adFormatId],
    queryFn: () => api.getOffersByAdFormat(adFormatId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["offers"])
        ?.filter((offer) => offer.adFormat.id === adFormatId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["offers"])?.dataUpdatedAt,
  });

export const getOffersBySpecTemplateQueryOptions = (
  specTemplateId,
  queryClient
) =>
  queryOptions({
    queryKey: ["specTemplate.offers", specTemplateId],
    queryFn: () => api.getOffersBySpecTemplate(specTemplateId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["offers"])
        ?.filter((offer) => offer.specTemplate.id === specTemplateId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["offers"])?.dataUpdatedAt,
  });

export const getOffersByAdOpsObjectiveQueryOptions = (
  adOpsObjectiveId,
  queryClient
) =>
  queryOptions({
    queryKey: ["adOpsObjective.offers", adOpsObjectiveId],
    queryFn: () => api.getOffersByAdOpsObjective(adOpsObjectiveId),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () =>
      queryClient
        .getQueryData(["offers"])
        ?.filter((offer) => offer.adOpsObjective.id === adOpsObjectiveId),
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(["offers"])?.dataUpdatedAt,
  });

export const getOfferQueryOptions = (id, queryClient) => {
  let queryKey;

  return queryOptions({
    queryKey: ["offer", id],
    queryFn: () => api.getOffer(id),
    refetchOnWindowFocus: "always",
    staleTime: STALE_TIME,
    initialData: () => {
      // if "offers" query exist, we use it to init data
      if (queryClient.getQueryState(["offers"])) {
        queryKey = ["offers"];
        return queryClient
          .getQueryData(["offers"])
          ?.find((offer) => offer.id === id);
      }

      // otherwise, we try to find the offer in one of the "platform.offers" queries
      let found;

      queryClient
        .getQueriesData({ queryKey: ["platform.offers"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((offer) => {
            if (offer.id === id) {
              found = offer;
              queryKey = query[0];
            }
          });
        });

      // otherwise, we try to find the offer in one of the "country.offers" queries

      queryClient
        .getQueriesData({ queryKey: ["country.offers"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((offer) => {
            if (offer.id === id) {
              found = offer;
              queryKey = query[0];
            }
          });
        });

      // otherwise, we try to find the offer in one of the "adFormat.offers" queries

      queryClient
        .getQueriesData({ queryKey: ["adFormat.offers"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((offer) => {
            if (offer.id === id) {
              found = offer;
              queryKey = query[0];
            }
          });
        });

      // otherwise, we try to find the offer in one of the "specTemplate.offers" queries

      queryClient
        .getQueriesData({ queryKey: ["specTemplate.offers"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((offer) => {
            if (offer.id === id) {
              found = offer;
              queryKey = query[0];
            }
          });
        });

      // otherwise, we try to find the offer in one of the "adOpsObjective.offers" queries

      queryClient
        .getQueriesData({ queryKey: ["adOpsObjective.offers"] })
        .forEach((query) => {
          const data = query[1];
          data.forEach((offer) => {
            if (offer.id === id) {
              found = offer;
              queryKey = query[0];
            }
          });
        });

      return found;
    },
    initialDataUpdatedAt: () =>
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
  });
};

// useQuery

export const useGetOffers = () => useQuery(getOffersQueryOptions());

export const useGetOffer = (id) => {
  const queryClient = useQueryClient();
  return useQuery(getOfferQueryOptions(id, queryClient));
};

// useSuspenseQuery

export const useSuspenseGetOffers = () =>
  useSuspenseQuery(getOffersQueryOptions());

export const useSuspenseGetOffersByPlatform = (platformId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getOffersByPlatformQueryOptions(platformId, queryClient)
  );
};

export const useSuspenseGetOffersByAdFormat = (adFormatId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getOffersByAdFormatQueryOptions(adFormatId, queryClient)
  );
};

export const useSuspenseGetOffersByCountry = (countryId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getOffersByCountryQueryOptions(countryId, queryClient)
  );
};

export const useSuspenseGetOffersBySpecTemplate = (specTemplateId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getOffersBySpecTemplateQueryOptions(specTemplateId, queryClient)
  );
};

export const useSuspenseGetOffersByAdOpsObjective = (adOpsObjectiveId) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(
    getOffersByAdOpsObjectiveQueryOptions(adOpsObjectiveId, queryClient)
  );
};

export const useSuspenseGetOffer = (id) => {
  const queryClient = useQueryClient();
  return useSuspenseQuery(getOfferQueryOptions(id, queryClient));
};
