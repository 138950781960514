import { Fragment } from "react";
import Logo from "./Logo";
import { Box, Typography } from "@material-ui/core";
import Spinner from "./Spinner"

const InitializingScreen = () => (

  // Rendering
  
  <Fragment>
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2000,
      }}
    >
      <Logo />
      <Box sx={{ mt: 3 }}>
        <Typography color="textPrimary" variant="h5">
          Initializing...
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Spinner />
      </Box>
    </Box>
  </Fragment>
);

export default InitializingScreen;
