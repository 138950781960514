import {
  getETLTaskQueryOptions,
  getETLTasksByETLRunQueryOptions,
} from "../../queries/ETLTasks";

// ETLTasks are pre-fetched when a user hover a 'ETLTasks' link within a ETLRun
export const ETLTaskListByETLRunLoader = ({
  params,
  context: { queryClient },
}) => {
  const { ETLRunId } = params;
  
  queryClient.ensureQueryData(
    getETLTasksByETLRunQueryOptions(ETLRunId)
  );
};

// ETLTask is pre-fetched when a user hover a 'view ETLTask' link
export const ETLTaskViewLoader = ({ params, context: { queryClient } }) => {
  const { ETLTaskId } = params;
  queryClient.ensureQueryData(getETLTaskQueryOptions(ETLTaskId, queryClient));
};
